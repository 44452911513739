

























import { Component, PropSync } from 'vue-property-decorator'

import { ConfigForm } from './_abstract/ConfigForm.vue'
import { IFields, IForm } from '../contracts/form'
import Field from './Elements/Field/Field.vue'
import { AnyObject } from '@movecloser/front-core'
import Fieldset from './Elements/Fieldset/Fieldset.vue'

@Component<SettingsForm>({
  name: 'SettingsForm',
  components: { Fieldset, Field },
  created (): void {
    this.fillDefaults(this.form.fields, this.payload)
    console.log(this.payload)
  }
})
export class SettingsForm extends ConfigForm {
  @PropSync('formData', { type: Object, required: true })
  public payload!: AnyObject

  @PropSync('formConfig', { type: Object, required: true })
  public form!: IForm

  public loading: boolean = false

  public onSubmit () {
    this.$emit('submit', { reproject: true, global: false })
  }

  public fillDefaults (fields: IFields[], payload: AnyObject) {
    fields.forEach((field) => {
      if ('default' in field && !(field.name in payload) && field.default) {
        payload[field.name] = field.default
      }

      if ('fields' in field && 'name' in field && !(field.name in payload) && field.name) {
        payload[field.name] = {}
        this.fillDefaults(field.fields, payload[field.name])
      }
    })
  }
}

export default SettingsForm
